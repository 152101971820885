import { createAsyncThunk } from "@reduxjs/toolkit";
import OrderService from "../../../services/OrderService";
import { ICompaniesQuery } from "../../../types/ICompany";
import { IOrderNew, IOrderNewWithItems, IOrderUpdateOrderItems } from "../../../types/IOrder";

export const addOrder = createAsyncThunk(
  'ORDER/addOrder',
  async (data: IOrderNewWithItems, {rejectWithValue}) => {
    try {
      return await (await OrderService.addOrder(data)).data;
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
);

export const getOrderByID = createAsyncThunk(
  'ORDER/getOrderByID',
  async (orderID: string, {rejectWithValue}) => {
    try {
      return await (await OrderService.getOrderByID(orderID)).data;
      
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
);

export const getAllOrders = createAsyncThunk(
  'ORDER/getAllOrders',
  async ({userID, query}: {userID: string, query: ICompaniesQuery}, {rejectWithValue}) => {
    try {
      return await (await OrderService.getAllOrders(userID, query)).data;
      
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
);

export const updateOrderItemsByOrderID = createAsyncThunk(
  'ORDER/updateOrderItemsByOrderID',
  async (data: IOrderUpdateOrderItems, {rejectWithValue}) => {
    try {
      return await (await OrderService.updateOrderItemsByOrderID(data)).data;
      
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
);

export const updateOrderStatus = createAsyncThunk(
  'ORDER/updateOrderStatus',
  async ({orderID, status}: {orderID: string, status: string}, {rejectWithValue}) => {
    try {
      return await (await OrderService.updateOrderStatus(orderID, status)).data;
      
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
);

export const deleteOrderByID = createAsyncThunk(
  'ORDER/deleteOrderByID',
  async (orderItemsID: string, {rejectWithValue}) => {
    try {
      return await (await OrderService.deleteOrderByID(orderItemsID)).data;
      
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  }
);