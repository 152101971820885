import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import './pagenotfound.scss';

const PageNotFoundInner: FC = () => {
  const navigate = useNavigate();
  
  return (
    <div className='pagenotfound'>
      <div className="pagenotfound__text">
        Страница не найдена!
      </div>
      <button onClick={() => navigate('/')} className="pagenotfound__button">На главную</button>
    </div>
  );
};

export const PageNotFound = React.memo(PageNotFoundInner);
